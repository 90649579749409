import $ from 'jquery';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import './page.scss';
import 'regenerator-runtime/runtime';

$(document).ready(function() {
    const savedFormData = Cookies.get('formData');
    if (savedFormData) {
        const formData = JSON.parse(savedFormData);
        console.log('Retrieved form data from cookies:', formData);
    }
});
